import { Controller } from "stimulus";
import { enter, leave, toggle } from 'el-transition'

export default class extends Controller {
  static targets = ['item']

  connect () {
    const query = window.innerWidth <= 1023
      ? 'div#sidebar-panel button#sidebar-btn-toggle-dropdown'
      : 'div#mobile-desktop button#sidebar-btn-toggle-dropdown';

    const elements = document.querySelectorAll(query);
    const activeTabs = JSON.parse(localStorage.getItem('tab-active')) || [];

    elements.forEach(el => {
      if (activeTabs.includes(el.getAttribute('aria-controls'))) {
        this.toggleDropdown(el);
      }
    });
  }

  toggleElement (id, action) {
    const element = document.getElementById(id);
    action === 'open' ? enter(element) : leave(element);
  }

  closeSidebar () {
    ['sidebar-menu', 'sidebar-overlay', 'sidebar-panel'].forEach(id => this.toggleElement(id, 'close'));
  }

  openSidebar () {
    ['sidebar-menu', 'sidebar-overlay', 'sidebar-panel'].forEach(id => this.toggleElement(id, 'open'));
  }

  toggleDropdown (event) {
    const domElement = event.target || event;
    const menuID = domElement.getAttribute('aria-controls');
    const toggleIcon = domElement.querySelector('#toggle-icon');
    toggleIcon.classList.toggle('rotate-90');

    const isExpanded = domElement.getAttribute('aria-expanded') === 'true';
    let tabActivesValues = JSON.parse(localStorage.getItem('tab-active')) || [];

    if (isExpanded && event.target) {
      const index = tabActivesValues.indexOf(menuID);
      if (index !== -1) {
        tabActivesValues.splice(index, 1);
      }
    } else if (!tabActivesValues.includes(menuID)) {
      tabActivesValues.push(menuID);
    }

    localStorage.setItem('tab-active', JSON.stringify(tabActivesValues));
    domElement.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
    document.querySelectorAll(`ul#${menuID}`).forEach(nested => toggle(nested));
  }

  filter (event) {
    const searchTerm = event.target.value.toLowerCase().trim();

    this.itemTargets.forEach(item => {
      const parentOfItem = item.parentElement.parentElement.querySelector('#sidebar-btn-toggle-dropdown');
      const itemText = item.textContent.toLowerCase().trim();
      const itemDisplay = itemText.includes(searchTerm) ? 'block' : 'none';

      item.style.display = itemDisplay;
      parentOfItem.style.display = itemDisplay === 'block' ? 'flex' : 'none';

      const childrenVisible = Array.from(parentOfItem.parentElement.querySelectorAll('li[data-sidebar-target="item"]'))
      .some(child => getComputedStyle(child).display !== 'none');

      parentOfItem.style.display = childrenVisible ? 'flex' : 'none';
    });
  }
}
