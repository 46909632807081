//  This file was created by the botyglot-kickoff-generators gem, using
//  the command `rails g botyglot_common:kickoff:botyglot_common:install`
//
//  You can upload this file as needed, but the recommended practice
//  involves updating the file in the gem, updating the gem reference
//  in the project, and then running the command again.
//
//  Please ensure that everything is up to your expectations before
//  committing the changes.

import { Controller } from "@hotwired/stimulus"
import { FormStore } from 'botyglot-common-js'

// OPTIONAL: import a custom vuex plugin that allows adding additional behaviour
// import MyPlugin from '../forms/plugins/my_plugin'
import codePreviewPlugin from '../forms/plugins/code_preview'

// OPTIONAL: use custom components
// import MyComponent from '../forms/components/my_component.vue'

export default class extends Controller {

  static values = {
    initialTouch: Boolean, // if true then all inputs are considered as touched (by default the value is false)
    disableValidation: Boolean, // if true then user inputs will not be revalidated on the fly
    hideValidationErrors: Boolean // if true then the validation messages will not be displayed
  }

  getHttpMethod(element) {
    let methodInput = element.querySelector("input[name='_method']");
    if (methodInput) {
      return methodInput.value
    } else {
      return null
    }

  }
  connect() {
    let { controller, values: serializedValues, validationUrl, ...others} = this.element.dataset
    let values = JSON.parse(serializedValues || {});
    let authenticityToken = this.element.querySelector("input[name='authenticity_token']")?.value
    let httpMethod = this.getHttpMethod(this.element) || 'POST'
    let globalAuthenticityToken = document.querySelectorAll('meta[name=csrf-token]')[0].content

    const app = new FormStore(Object.assign( {
      authenticityToken: authenticityToken,
      additionalComponents: {
        // 'k-my_component': MyComponent
      }, // optional
      plugins:[
        // MyPlugin,
        codePreviewPlugin,
      ],  // optional
      element: this.element.firstElementChild,
      globalAuthenticityToken: globalAuthenticityToken,
      httpMethod: httpMethod,
      validationUrl: validationUrl,
      values: values,
      initialTouch: this.initialTouchValue,
      disableValidation: this.disableValidationValue,
      hideValidationErrors: this.hideValidationErrorsValue,
    }, others)).app
  }
}
