import { Controller } from 'stimulus'
import { Api } from "k-utils-js";
import { transition } from "../../helpers/transition";

export default class extends Controller {
  static values = { url: String }
  static targets = ['backdrop', 'menu', 'fixedBackground', 'input']

  isDragging = false;
  offsetX = 0;
  offsetY = 0;


  // Bind the handleKeydown function to this instance to ensure the same instance is used for both adding and removing event listeners
  boundHandleKeydown = this.handleKeydown.bind(this);
  boundHandleMouseMove = this.handleMouseMove.bind(this);
  boundHandleMouseUp = this.handleMouseUp.bind(this);

  inputTargetConnected(element) {
    element.focus()
  }

  connect () {
    document.addEventListener('keydown', this.boundHandleKeydown)
    this.menuTarget.addEventListener('mousedown', this.handleMouseDown.bind(this));
    document.addEventListener('mousemove', this.boundHandleMouseMove);
    document.addEventListener('mouseup', this.boundHandleMouseUp);
  }

  // Helper function to toggle the command palette view
  toggleModalView (isVisible) {
    const body = document.body

    if (isVisible) {
      body.classList.add('overflow-hidden');
      transition(this.backdropTarget, true);
      transition(this.menuTarget, true);
      transition(this.fixedBackgroundTarget, true);
      this.fetchSearchResults();
    } else {
      body.classList.remove('overflow-hidden');
      transition(this.backdropTarget, false);
      transition(this.menuTarget, false);
      transition(this.fixedBackgroundTarget, false);
    }
  }

  handleKeydown (event) {
    const isModalOpen = !this.menuTarget.classList.contains('hidden');

    // Ctrl/Cmd + K is pressed
    if ((event.metaKey || event.ctrlKey) && event.key === 'k' && !this.element.contains(event.target)) {
      this.toggleModalView(!isModalOpen);
    }

    // Escape is pressed or clicked outside
    if (event.key === 'Escape' || event.target === this.fixedBackgroundTarget) {
      this.toggleModalView(false);
    }
  }

  fetchSearchResults () {
    const onSuccess = (response) => {
      const injectHtmlContent = document.querySelector('#command-palette-results')
      injectHtmlContent.classList.remove('flex')
      injectHtmlContent.innerHTML = response.data;
    };

    const onError = (data) => {
      console.error("Error fetching search results:", data);
    };

    Api.sendRequest({
      url: '/global_search_results',
      params: { variant: "modal" },
      method: 'GET',
      onSuccess: onSuccess,
      onError: onError,
      delay: false,
      headers: { "Accept": 'text/html' }
    });
  }

  handleMouseDown(event) {
    this.isDragging = true;
    this.offsetX = event.clientX - this.menuTarget.getBoundingClientRect().left;
    this.offsetY = event.clientY - this.menuTarget.getBoundingClientRect().top;
  }

  handleMouseMove(event) {
    if (this.isDragging) {
      let x = event.clientX - this.offsetX;
      let y = event.clientY - this.offsetY;

      const targetWidth = this.menuTarget.offsetWidth;
      const targetHeight = this.menuTarget.offsetHeight;

      if (x < 0) x = 0;
      if (y < 0) y = 0;
      if (x > window.innerWidth - targetWidth) x = window.innerWidth - targetWidth;
      if (y > window.innerHeight - targetHeight) y = window.innerHeight - targetHeight;

      this.menuTarget.style.left = `${x}px`;
      this.menuTarget.style.top = `${y}px`;
    }
  }

  handleMouseUp() {
    this.isDragging = false;
  }


  disconnect () {
    document.removeEventListener('keydown', this.boundHandleKeydown);
    document.removeEventListener('mousemove', this.boundHandleMouseMove);
    document.removeEventListener('mouseup', this.boundHandleMouseUp);
  }
}
