import { Controller } from "stimulus"
import Mousetrap from 'mousetrap'
import { toggle, leave } from "el-transition";

export default class extends Controller {
  static targets = ['activeTab', 'button']

  connect () {
    Mousetrap.bind(['ctrl+w'], (event) => {
      event.preventDefault()
      this.closeTab()
    });
  }

  toggleDropdown (event) {
    const tabs = document.querySelectorAll('div[aria-labelledby="tab-menu"]')
    const menuDropdownId = event.target.parentElement.getAttribute('data-dropdown-toggle');
    const menuDropdown = document.getElementById(menuDropdownId)

    const newTabs = Array.from(tabs).filter(tab => tab !== menuDropdown);

    newTabs.forEach(tab => {
      if (tab && !tab.classList.contains('hidden')) {
        leave(tab)
      }
    })

    toggle(menuDropdown)
  }

  closeTab () {
    if (!this.hasActiveTabTarget) return;

    const formLink = this.activeTabTarget.querySelector('form');
    if (formLink) {
      formLink.submit();
    }
  }
}
