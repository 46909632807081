import { Controller } from "stimulus";
import { toggle } from "el-transition";

export default class extends Controller {
  static targets = ['button']

  connect () {
    this.buttonTarget.addEventListener('click', this.toggleDropdown)
  }

  toggleDropdown () {
    const menuDropdown = document.getElementById('menu-dropdown')
    toggle(menuDropdown)
  }
}
