// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

console.log('Vite ⚡️ Rails')

import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails";
import "~/channels/index.js"
import "~/pagy.js.erb"

import * as Routes from '~/routes.js.erb';
window.Routes = Routes;

ActiveStorage.start()

import "~/controllers/index.js"
import '~/stylesheets/application.scss'

// required in order to play with MicroModal in console or in storybook
import MicroModal from 'micromodal/dist/micromodal.min'
window.MicroModal = MicroModal;

