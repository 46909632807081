export async function transition (element, state) {
  if (!!state) {
    enter(element)
  } else {
    leave(element)
  }
}

export const enter = async (element) => {
  const transitionClasses = element.dataset.transitionEnter || "enter"
  const fromClasses = element.dataset.transitionEnterFrom || "enter-from"
  const toClasses = element.dataset.transitionEnterTo || "enter-to"

  // Prepare transition
  element.classList.add(...transitionClasses.split(" "))
  element.classList.add(...fromClasses.split(" "))
  element.classList.remove(...toClasses.split(" "))
  element.classList.remove("hidden")

  await nextFrame()

  element.classList.remove(...fromClasses.split(" "))
  element.classList.add(...toClasses.split(" "))

  try {
    await afterTransition(element)
  } finally {
    element.classList.remove(...transitionClasses.split(" "))
  }
}

export const leave = async (element) => {
  const transitionClasses = element.dataset.transitionLeave || "leave"
  const fromClasses = element.dataset.transitionLeaveFrom || "leave-from"
  const toClasses = element.dataset.transitionLeaveTo || "leave-to"

  // Prepare transition
  element.classList.add(...transitionClasses.split(" "))
  element.classList.add(...fromClasses.split(" "))
  element.classList.remove(...toClasses.split(" "))

  await nextFrame()

  element.classList.remove(...fromClasses.split(" "))
  element.classList.add(...toClasses.split(" "))

  try {
    await afterTransition(element)
  } finally {
    element.classList.remove(...transitionClasses.split(" "))
    element.classList.add("hidden")
  }
}

const nextFrame = () => {
  return new Promise(resolve => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve)
    })
  })
}

const afterTransition = (element) => {
  return Promise.all(element.getAnimations().map(animation => animation.finished))
}
